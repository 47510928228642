// ALL REDUCERS
export const LOGOUT = 'LOGOUT';

// USER REDUCERS TYPES
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_USER = 'SET_USER';

// UI REDUCERS TYPES
export const LOADING_UI = 'LOADING_UI';
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const ORDER_SUCCESS_IN_TIME = 'ORDER_SUCCESS_IN_TIME';
export const ORDER_SUCCESS_OUT_OF_TIME = 'ORDER_SUCCESS_OUT_OF_TIME';
export const ORDER_SUCCESS_DONE = 'ORDER_SUCCESS_DONE';
export const ASK_DELETE_CONFIRM = 'ASK_DELETE_CONFIRM';
export const HIDE_DELETE_CONFIRM = 'HIDE_DELETE_CONFIRM';

// DATA REDUCERS TYPES
export const LOADING_DATA = 'LOADING_DATA';
export const SET_FUTURE_ORDERS = 'SET_FUTURE_ORDERS';
export const SET_PAST_ORDERS = 'SET_PAST_ORDERS';
export const SET_RECURRENT_ORDERS = 'SET_RECURRENT_ORDERS';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_BREADS = 'SET_BREADS';
export const SET_ORDER = 'SET_ORDER';
export const CLEAR_ORDER = 'CLEAR_ORDER';
